import React, { Component } from 'react'
import {
  Panel as RBPanel, PanelGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton
} from 'react-bootstrap'
import SVG from 'react-inlinesvg'

const { Title, Heading, Collapse, Body } = RBPanel

class LayerButtonGroup extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      activeLayer: this.props.layers ? this.props.layers[0].id : undefined
    }
    this.onToggleChange = this.onToggleChange.bind(this)
  }
  onToggleChange(targetLayerId) {
    const layers = []
    const changes = []
    // Remove existing active selection
    layers.push(this.state.activeLayer)
    changes.push('remove')
    const newLayer = this.props.layers.find(layer => layer.id === targetLayerId)
    if (!newLayer) return
    // Add new selection
    layers.push(newLayer)
    changes.push('add')
    this.props.changeMapLayers(layers, changes)
    this.setState({
      activeLayer: newLayer.id
    })
  }
  render() {
    if (!this.props.layers) {
      return null
    }
    return (
      <div className="layer-button-group">
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="layers"
            defaultValue={this.state.activeLayer}
            value={this.props.allActiveLayers.includes(this.state.activeLayer) ? this.state.activeLayer : null}
            onChange={this.onToggleChange}
          >
            {
              this.props.layers.map(layer => {
                const active = layer.id === this.state.activeLayer && this.props.allActiveLayers.includes(layer.id)
                return (
                  <ToggleButton
                    className={active ? 'btn-active' : ''}
                    key={layer.id}
                    value={layer.id}>
                    {
                      layer.name
                    }
                  </ToggleButton>
                )
              })
            }
          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }
}

export class Panel extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      active: new Set([]),
    }
  }
  render() {
    // Collapse has onEnter etc, that we can hook map events to
    // Panel has an onToggle event that can do the same
    const panels = this.props.sections.map((section, i) => {
      return (
        <RBPanel eventKey={`${i}`} key={`${i}`}>
          <div className={`panel-inner-container ${(this.state.active.has(i) ? 'active' : '')}`}>
            <Heading>
              <Title toggle>
                {section.heading}
                <SVG src={process.env.REACT_APP_BASENAME + section.icon || ''}/>
              </Title>
            </Heading>
            <Collapse
              onEntering={() => {
                if (section.layers && section.layers[0]) {
                  this.props.addMapLayer(section.layers[0])
                }
                if (section.documentHtml) {
                  this.props.setDocument(section.documentHtml)
                }
                this.state.active.add(i)
                this.setState({active: this.state.active})
              }}
              onExiting={() => {
                if (section.documentHtml) {
                  this.props.setDocument(null)
                }
                this.state.active.delete(i)
                this.setState({active: this.state.active})
              }}
            >
              {(section.text || section.layers) && <Body>
                <div dangerouslySetInnerHTML={{ __html: section.text }}></div>
                <LayerButtonGroup
                  layers={section.layers}
                  addMapLayer={this.props.addMapLayer}
                  removeMapLayer={this.props.removeMapLayer}
                  changeMapLayers={this.props.changeMapLayers}
                  allActiveLayers={this.props.activeLayers}
                />
              </Body>}
            </Collapse>
          </div>
        </RBPanel>
      )
    })
    return (<PanelGroup
      activeKey={this.state.activeKey}
      className="panels-container"
      id={this.props.id || 'PanelGroup'}
    >
      {panels}
    </PanelGroup>)
  }
}
