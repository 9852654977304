import auth0 from 'auth0-js'

import history from '../history'
import { getGroup, getUser, getAppData } from '../getGroup'
import { AUTH_CONFIG } from './auth0-variables'

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    leeway: 5,
    audience: 'groups-backend-dev',
    scope: 'profile email groups' // Get permissions : https://auth0.com/docs/scopes/current
  })

  constructor() {
    this.signinUser = this.signinUser.bind(this)
    this.signup = this.signup.bind(this)
    this.logout = this.logout.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
  }

  signinUser(email, password, callback) {
    this.auth0.login({
      realm: AUTH_CONFIG.realm,
      username: email,
      password,
      scope: 'profile email groups'
    }, (err, data) => {
      if (err) {
        console.error(err)
        callback(err)
      } else if (data && data.error) {
        console.error(data.err)
        callback(data.error)
      } else {
        this.setSession(data)
      }
    })
  }

  signup(email, password, callback) {
    this.auth0.signup({
      connection: AUTH_CONFIG.realm,
      email, password, user_metadata: {}
    }, (err) => {
      if (err) {
        console.error(err)
        callback(err)
      } else {
        callback(null) // Success; now get user to sign in
      }
    })
  }

  changePassword(email, callback) {
    this.auth0.changePassword({
      email, connection: AUTH_CONFIG.realm
    }, err => {
      if (err) {
        console.error(err)
      } else {
        callback()
      }
    })
  }

  handleAuthentication() {
    // When responseType includes "token", a nonce is automatically created and
    // added to local storage, and then used in webAuth.parseHash
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
      } else if (err) {
        console.error(err)
        this.logout()
      }
    })
  }

  setSession(authResult) {
    const { idTokenPayload, idToken, accessToken, scope } = authResult
    const groupId = getGroup(idTokenPayload)
    const user = getUser(idTokenPayload)
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
    localStorage.setItem('access_token', accessToken)
    localStorage.setItem('id_token', idToken)
    localStorage.setItem('name', idTokenPayload.name)
    localStorage.setItem('expires_at', expiresAt)
    localStorage.setItem('scope', scope)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('group', getGroup(idTokenPayload))
    getAppData(groupId, accessToken).then(appData => {
      localStorage.setItem('app_data', JSON.stringify(appData))
      history.replace('/')
    })
  }

  logout() {
    // TODO webAuth.logout ?
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('name')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('scope')
    localStorage.removeItem('group')
    localStorage.removeItem('user')
    localStorage.removeItem('app_data')
    history.replace('/')
  }

  group() {
    return localStorage.getItem('group')
  }

  userEmail() {
    return JSON.parse(localStorage.getItem('user'))['email'] || ''
  }

  userName() {
    return JSON.parse(localStorage.getItem('user'))['nickname'] || ''
  }

  appData() {
    return JSON.parse(localStorage.getItem('app_data'))
  }

  isAuthenticated() {
    // Check whether the current time is past the access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }
}
