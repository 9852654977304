import React from 'react'
import { omit } from 'lodash'

export const Label = (props, height) => {
  const textProps = {
    alignmentBaseline: 'middle',
    fill: 'currentColor',
    y: height*0.75
  }
  return (
    <text {...textProps} {...omit(props, 'text')}>{props.text}</text>
  )
}
