import { GROUPS_IN_PRIORITY_ORDER } from './data/data.js'

const NAMESPACE = "https://prototype.cartisanpublishing.com"

export const getGroup = (idPayload) => {
  const prop = `${NAMESPACE}/groups`
  const groups = idPayload[prop] || []
  for (let i = GROUPS_IN_PRIORITY_ORDER.length; i--;){
    let group = GROUPS_IN_PRIORITY_ORDER[i]
    if (groups.includes(group)) {
      return group
    }
  }
  return 'Default'
}

export const getUser = (idPayload) => {
  return idPayload[`${NAMESPACE}/user`] || {}
}

export const getAppData = (groupId, accessToken) => {
  const uri = process.env.REACT_APP_GROUPS_URI
  return fetch(
    `${uri}?groupId=${groupId}&jwt=${accessToken}`
  ).then(response => {
    return response.json()
  }).then(appData => {
    return appData.Item
  })
}
