import React from 'react'

import { Label } from './Label'
import { propParser, interpolateHandler_getDomain } from './utils'
import { LegendText } from './LegendText'

// Note, ignores the following properties:
//  - fill-pattern
//  - fill-sort-key
//  - fill-translate
//  - fill-translate-anchor
// NB fill stroke is limited to 1px, there is no way to control this,
//  see https://stackoverflow.com/questions/44837565/increase-the-stroke-width-of-a-polygon-in-mapbox-studio
//  and https://github.com/mapbox/mapbox-gl-js/issues/4088
export function FillLegend(width, height, legendProps) {
  const {layer, legendText} = legendProps
  if (Array.isArray(layer.paint['fill-color']) && layer.paint['fill-color'][0] === 'case') {
    return null // Cannot handle case (conditional or feature-state)
  }
  const visibility = (layer.layout['visibility'] || "visible") === 'visible' ? 'visible' : 'hidden'
  const fillPattern = propParser(layer.paint['fill-pattern'], null)
  const fillAntialias = propParser(layer.paint['fill-antialias'], true)
  const fillColor = propParser(layer.paint['fill-color'], '#000000')
  const svgRectProps = {
    'shapeRendering': fillAntialias ? "auto" : "crispEdges",
    // fill-pattern disables fill-color
    'fill': (fillPattern === null) ? fillColor : null,
    'fillOpacity': propParser(layer.paint['fill-opacity'], 1),
    // fill-pattern: disables fill-outline-color; requires fill-antialias; defaults to fill-color
    'stroke': ((fillPattern === null) && (fillAntialias === true)) ? propParser(layer.paint['fill-outline-color'], fillColor) : null,
  }
  let interpolateProps = []
  let labels = []
  Object.keys(svgRectProps).forEach(prop => {
    if (Array.isArray(svgRectProps[prop])) {
      interpolateProps.push(prop)
      const _prop = prop === 'fill' ? layer.paint['fill-color'] : prop === 'stroke' ? (layer.paint['fill-outline-color'] || layer.paint['fill-color']) : undefined
      const domain = interpolateHandler_getDomain(_prop)
      labels = svgRectProps[prop].map((value, i) => {
        return {
          text: ((x) => [`< ${x}`, `${x}`, `${x} +`][i])(domain[i]),
          x: [0, width/2, width][i],
          textAnchor: ['start', 'middle', 'end'][i]
        }
      })
    }
  })

  labels = labels.length ? labels.map((labelData, i) => {
    return Label({...labelData, key: i}, height)
  }) : null

  const lowStop = Array.isArray(svgRectProps['fill']) ? svgRectProps['fill'][0] : svgRectProps['fill']
  const highStop = Array.isArray(svgRectProps['fill']) ? svgRectProps['fill'][svgRectProps['fill'].length-1] : svgRectProps['fill']

  // TODO I think this would work better as discrete stops rather than a range
  return (
    <div className="legend fill-legend">
      <LegendText text={legendText} />
      {<svg width={width} height={height}>
        <defs>
          <linearGradient id={layer.id}>
            <stop className="low" offset="0%" stopColor={lowStop}/>
            <stop className="high" offset="100%" stopColor={highStop}/>
          </linearGradient>
        </defs>
        <g visibility={visibility}>
          <rect width={width} height={15} x={0} y={15} {...svgRectProps} fill={`url(#${layer.id})`}/>
          <g>{labels}</g>
        </g>
      </svg>}
    </div>
  )
}
