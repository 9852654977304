import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import SVG from 'react-inlinesvg'

class Codes extends Component {
  render() {
    return (
      <ListGroup className="sub-list-group" style={{fontSize: '1em'}}>
        {
          this.props.codes.map(code => {
            return (
              <ListGroupItem className={this.props.activeId === code.id ? 'active' : ''} key={code.id} onClick={() => this.props.updateLastClickedId(code.id, this.props.handleMidBarTopic(code))}>
                {code.name}
                <SVG src={this.props.icon}/>
              </ListGroupItem>
            )
          })
        }
      </ListGroup>
    )
  }
}

export class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.itemExpansionToggle = this.itemExpansionToggle.bind(this)
    this.updateLastClickedId = this.updateLastClickedId.bind(this)
    this.state = {
      expandedItemIds: new Set([]),
      lastClickedId: null
    }
  }
  updateLastClickedId(id, callback) {
    this.setState({
      lastClickedId: id
    }, callback)
  }
  itemExpansionToggle(id) {
    const expandedItemIds = this.state.expandedItemIds
    if (!expandedItemIds.has(id)) {
      expandedItemIds.add(id)
    } else {
      expandedItemIds.delete(id)
    }
    this.setState({expandedItemIds})
  }
  render() {
    const elementContent = (category) => {
      if (category.type === 'CODE_VIEWER') {
        return (
          <div>
            <h5 onClick={() => this.itemExpansionToggle(category.id)}>{category.name}</h5>
            {
              ((category.collapsible && this.state.expandedItemIds.has(category.id)) || !category.collapsible ) &&
              <Codes
                codes={category.CODES}
                activeId={this.state.lastClickedId}
                updateLastClickedId={this.updateLastClickedId}
                handleMidBarTopic={this.props.handleMidBarTopic}
                icon={this.props.forwardIcon}
              />
            }

          </div>
        )
      } else if (category.type === 'MEMBER_VIEWER') {
        return (
          <div onClick={() => this.updateLastClickedId(null, this.props.handleMemberViewer(category))}>
            <h5>{category.name}<SVG src={this.props.forwardIcon}/></h5>
          </div>
        )
      } else if (category.type === 'AREA_VIEWER') {
        return (
          <div onClick={() => this.updateLastClickedId(null, this.props.handleAreaViewer(category))}>
            <h5>{category.name}<SVG src={this.props.forwardIcon}/></h5>
          </div>
        )
      } else {
        return null
      }
    }
    const categories = this.props.categories || []
    return (
      <div className="sidebar">
        <div className="sidebar-controls">
          <Button onClick={this.props.closeButtonClick}>
            <SVG src={this.props.closeIcon}/>
          </Button>
        </div>
        <div className="sidebar-commentary" dangerouslySetInnerHTML={{ __html: this.props.commentary }}></div>
        <ListGroup componentClass={'div'} className="sidebar-group">
        {
          categories.map(category => {
            return (
              <ListGroupItem key={category.id}>
                {elementContent(category)}
              </ListGroupItem>
            )
          })
        }
        </ListGroup>
        {this.props.instructions && (
          <div className="sidebar-actions">
            <Button onClick={() => this.updateLastClickedId(null, this.props.helpButtonClick)} className="rounded help">
              <SVG src={this.props.helpIcon}/>
            </Button>
          </div>
        )}
        {this.props.disclaimer && (
          <div className="sidebar-disclaimer" dangerouslySetInnerHTML={{ __html: this.props.disclaimer }} ></div>
        )}
      </div>
    );
  }
}
