import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, FormControl, ControlLabel, Button, HelpBlock, Tabs, Tab } from 'react-bootstrap'

import { Logo } from './Home/Logo/Logo'

const { Feedback } = FormControl

export class MustLogin extends Component {
  constructor(props, context) {
    super(props, context)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.getValidationState = this.getValidationState.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handlePasswordReset = this.handlePasswordReset.bind(this)
    this.handleSignupSubmit = this.handleSignupSubmit.bind(this)
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
    this.handleTabSelect = this.handleTabSelect.bind(this)
    this.state = {
      email: '',
      password: '',
      activeKey: 'login',
      signinError: null,
      signupError: null
    }
  }
  getValidationState() {
    // You cannot validate an email in JS: the only true method is email
    // verification itself. However an email must have something followed by
    // an @ followed by something. This check just gives feedback in case
    // a user has tried to enter a username or some other string into the
    // email field
    const email = this.state.email
    if (!email || !email.length) return null
    const simpleRe = /.+@.+/g
    const valid = simpleRe.test(email)
    return valid ? 'success' : 'warning'
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value })
  }
  handleTabSelect(activeKey) {
    this.setState({activeKey})
  }
  handlePasswordReset(e) {
    e.preventDefault()
    this.props.auth.changePassword(
      this.state.email,
      () => {
        this.setState({
          signinError: `We have sent a password reset request to ${this.state.email}. Please follow the instructions in the email and then sign in.`
        })
      }
    )
  }
  handleSignupSubmit(e) {
    e.preventDefault()
    if (!this.props.allowRegistration) {
      return
    }
    this.props.auth.signup(
      this.state.email,
      this.state.password,
      error => {
        if (error) {
          console.error(error)
          this.setState({signupError: error.name + ": " + error.policy})
        } else {
          // Success
          this.setState({
            signupError: null,
            signinError: `We have sent a verification email to ${this.state.email}. Please verify your email before signing in.`,
            activeKey: 'login'
          })
        }
      }
    )
  }
  handleLoginSubmit(e) {
    e.preventDefault()
    this.props.auth.signinUser(
      this.state.email,
      this.state.password,
      error => {
        if (error) {
          console.error(error)
          this.setState({signinError: error.description})
        }
      }
    )
  }
  render() {
    return (
      <Grid fluid className="must-login">
        <Row>
          <Col xs={8} sm={6} md={4} mdOffset={4} smOffset={3} xsOffset={2}>
            <Logo logo={this.props.logo} logoAlt={this.props.logoAlt}/>
            <h1>{this.props.appName}</h1>
            <p className="lede" dangerouslySetInnerHTML={{ __html: this.props.disclaimer}} ></p>
            <div className="login-dialog">
              <Tabs id="form-login" defaultActiveKey={'login'} activeKey={this.state.activeKey} onSelect={this.handleTabSelect}>
                <Tab eventKey={'login'} title="Sign in">
                  <form>
                    <FormGroup
                      controlId="login-email"
                      validationState={this.getValidationState()}
                    >
                      <ControlLabel>Email:</ControlLabel>
                      <FormControl
                        type="email"
                        value={this.state.email}
                        placeholder="Email address"
                        onChange={this.handleEmailChange}
                      />
                      <Feedback />
                    </FormGroup>
                    <FormGroup controlId="login-password">
                      <ControlLabel>Password:</ControlLabel>
                      <FormControl
                        type="password"
                        value={this.state.password}
                        placeholder="Password"
                        onChange={this.handlePasswordChange}
                      />
                    </FormGroup>
                    <Button id="button-login" type="submit" onClick={this.handleLoginSubmit}>Sign in</Button>
                    <Button id="button-password-reset" type="submit" onClick={this.handlePasswordReset}>Reset Password</Button>
                    {
                      this.state.signinError && (<HelpBlock>{this.state.signinError}</HelpBlock>)
                    }
                  </form>
                </Tab>
                {this.props.allowRegistration && (
                  <Tab id="form-signup" eventKey={'signup'} title="Request Account">
                    <form>
                      <FormGroup
                        controlId="register-email"
                        validationState={this.getValidationState()}
                      >
                        <ControlLabel>Email:</ControlLabel>
                        <FormControl
                          type="email"
                          value={this.state.email}
                          placeholder="Email address"
                          onChange={this.handleEmailChange}
                        />
                        <Feedback />
                      </FormGroup>
                      <FormGroup controlId="register-password">
                        <ControlLabel>Password:</ControlLabel>
                        <FormControl
                          type="password"
                          value={this.state.password}
                          placeholder="Password"
                          onChange={this.handlePasswordChange}
                        />
                      </FormGroup>
                      <Button id="button-password-register" type="submit" onClick={this.handleSignupSubmit}>Submit</Button>
                      {
                        this.state.signupError && (<HelpBlock>{this.state.signupError}</HelpBlock>)
                      }
                    </form>
                  </Tab>
                )}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Grid>
    )
  }
}
