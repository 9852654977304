import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/css/index.css';
import { makeMainRoutes } from './routes';
import 'typeface-pt-sans-narrow';

const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);
