import React, { PureComponent } from 'react';
import { Navbar, Form, Button } from 'react-bootstrap';

import {
  LOGO,
  LOGO_ALT
} from '../data/data.js'

const { Brand } = Navbar;

export class TopBar extends PureComponent {
  render () {
    return (
      <Navbar>
        <Brand>
          <img src={LOGO} alt={LOGO_ALT}/>
        </Brand>
        <Form inline>
          <Button onClick={this.props.logout}>
            <span>Log out</span>
          </Button>
        </Form>
      </Navbar>
    );
  }
}
