import React, { Component } from 'react';
import { Grid, Row, Col, Modal, Button } from 'react-bootstrap';

export class SplashModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true
    }
  }

  render() {
    const hide = () => this.setState({show: false})
    if (!this.props.splash) return null
    return (
      <div className="static-modal">
        <Modal
          className="home-splash-modal-container"
          animation={false}
          bsSize="large"
          show={this.state.show}
          onHide={hide}
        >
          <Modal.Body>
            <Grid fluid className="splash-container" >
              <Col xs={12} style={{backgroundImage: `url(${this.props.backgroundImageUrl})`}}>
                <Row key={'head'}>
                  <h2>{this.props.splash.head}</h2>
                </Row>
                <Row key={'subhead'}>
                  <p><span>{this.props.splash.subhead}</span></p>
                </Row>
                <Row key={'disclaimer'}>
                  <p className="splash-disclaimer" dangerouslySetInnerHTML={{ __html: this.props.splash.disclaimer}} ></p>
                </Row>
                <Row key={'lede'} id="lede">
                  <p className="splash-lede" dangerouslySetInnerHTML={{ __html: this.props.splash.lede}} ></p>
                </Row>
              </Col>
            </Grid>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={hide}>Start</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
