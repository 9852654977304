import React, { Component } from 'react';
import loading from './loading_test.svg';
import SVG from 'react-inlinesvg'

class Callback extends Component {
  render() {
    const style = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      top: '410px',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#2B4AE5',
    }

    return (
      <div style={style}>
        <SVG src={loading}/>
      </div>
    );
  }
}

export default Callback;
