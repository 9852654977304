import React from 'react'

import { Label } from './Label'
import { propParser } from './utils'
import { LegendText } from './LegendText'

const circle = (props) => {
  return (<g key={props.key}>
    <circle {...props} filter="url(#filter)"/>
  </g>)
}

const getPropsForIteration = (props, iter) => {
  let iterProps = {...props}
  Object.keys(iterProps).forEach(prop => {
    if (Array.isArray(iterProps[prop])) {
      iterProps[prop] = iterProps[prop][iter]
    }
  })
  return iterProps
}
// If any property is interpolatable...
// then the pattern needs to be repeated n times
// with appropraite features repeating
// one solution would be to return their properties as arrays of length n and
// then the renderer just does a lookup

// Note, ignores the following properties:
// - circle-pitch-alignment
// - circle-pitch-scale
// - circle-sort-key
// - circle-translate
// - circle-translate-anchor
export function CircleLegend(width, height, legendProps) {
  const {layer, legendText} = legendProps
  const visibility = (layer.layout['visibility'] || "visible") === 'visible' ? 'visible' : 'hidden'
  const svgCircleProps = {
    'stroke': propParser(layer.paint['circle-stroke-color'], '#000000'),
    'strokeOpacity': propParser(layer.paint['circle-stroke-opacity'], 1),
    'strokeWidth': propParser(layer.paint['circle-stroke-width'], 0),
    'fill': propParser(layer.paint['circle-color'], '#000000', true),
    'opacity': propParser(layer.paint['circle-opacity'], 1),
    'r': propParser(layer.paint['circle-radius'], 5)
  }
  let interpolateProps = []
  let labels = []
  Object.keys(svgCircleProps).forEach(prop => {
    if (Array.isArray(svgCircleProps[prop])) {
      interpolateProps.push(prop)
      if (prop === 'r') {
        // If the size changes ("graduated circles"), cx adjusts to compensate
        svgCircleProps.cx = [
          svgCircleProps['r'][0],
          width / 2,
          width - svgCircleProps['r'][svgCircleProps['r'].length-1]
        ]
      } else {
        // TODO even if it's not r, the cx will need to compensate... just possibly ignoring width (i.e. constant size, but changing colour)
      }
      labels = svgCircleProps[prop].map((value, i) => {
        return {
          text: [`< ${value}`, `${value}`, `${value} +`][i],
          x: [0, width/2, width][i],
          textAnchor: ['start', 'middle', 'end'][i]
        }
      })
    }
  })
  const iterations = interpolateProps.length ? 3 : 1
  if (iterations === 1) {
    height *= 0.5
  }

  const filters = {
    'feGaussianBlur': {
      in: 'SourceGraphic',
      stdDeviation: propParser(layer.paint['circle-blur'], 0)
    }
  }
  // Defaults taken from https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#circle
  const circles = Array.from(Array(iterations)).map((x, i) => {
    const circleProps = getPropsForIteration(svgCircleProps, i)
    const cx = circleProps.cx || circleProps.r
    return circle({key: i, ...circleProps, cx, cy: height*0.25})
  })

  labels = labels.length ? labels.map((labelData, i) => {
    return Label({...labelData, key: i}, height)
  }) : null
  return (
    <div className="legend circle-legend">
      <LegendText text={legendText} />
      {<svg width={width} height={height}>
        <defs>
          <filter id="filter" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur {...filters['feGaussianBlur']} />
          </filter>
        </defs>
        <g visibility={visibility}>
          <g>{circles}</g>
          <g>{labels}</g>
        </g>
      </svg>}
    </div>
  )
}
