import React from 'react'
import SVG from 'react-inlinesvg'

import { propParser } from './utils'
import { LegendText } from './LegendText'

export function SymbolLegend(width, height, legendProps) {
  const {layer, legendText} = legendProps
  let iconImage = layer.layout["icon-image"]

  const svgRectProps = {
    src: `process.env.REACT_APP_BASENAME}/images/icons/${iconImage}.svg`,
    color: propParser(layer.paint['icon-color'], '#000000'),
    opacity: iconImage ? propParser(layer.paint['icon-opacity'], 1) : null,
  }
  return (
    <div className="legend symbol-legend">
      <SVG className="legend-symbol-icon" {...svgRectProps}/>
      <LegendText text={legendText} />
    </div>
  )
}
