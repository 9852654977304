import React from 'react'

import { LegendText } from './LegendText'

export function FallbackLegend(width, height, legendProps) {
  const {legendText} = legendProps
  return (
    <div className="legend fallback-legend">
      <LegendText text={legendText} />
    </div>
  )
}
