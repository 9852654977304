import React, { Component } from 'react';

export class Logo extends Component {
  render() {
    return (
      <div className="logo-container">
        <img className="logo" src={this.props.logo} alt={this.props.alt}/>
      </div>
    );
  }
}
