// Accepts a Mapbox GL JS layer, and returns a React component that inclues the
// rendered legend
import React, { Component } from 'react'
import SVG from 'react-inlinesvg'
import { Button } from 'react-bootstrap';

import {
  FallbackLegend,
  CircleLegend, FillLegend, LineLegend, SymbolLegend
} from './LegendTypes'

import {
  EYE_OPEN_ICON, EYE_CLOSE_ICON, CLOSE_ICON
} from '../../data/data.js'

const graphic = ({props, width, height}) => {
  const layer = props.layer
  try {
    if (layer.type === 'circle') {
      return CircleLegend(width, height, props)
    } else if (layer.type === 'fill') {
      return FillLegend(width, height, props) || FallbackLegend(width, height, props)
    } else if (layer.type === 'line') {
      return LineLegend(width, height, props)
    } else if (layer.type === 'symbol') {
      return SymbolLegend(width, height, props)
    } else {
      // Other possible layer types:
       // - background
       // - raster
       // - fill-extrusion
       // - heatmap
       // - hillshade
       // - sky
      return FallbackLegend(width, height, props)
    }
  } catch (error) {
    console.debug({error})
    return FallbackLegend(width, height, props)
  }
}

class Legend extends Component {
  removeLayer() {
    this.props.removeMapLayer()
  }
  toggleVisibility() {
    this.props.toggleVisibility()
  }
  render() {
    if (!this.props.layer) return null
    const height = 58 // this.props.height
    const width = this.props.width * 0.8
    const isVisible = this.props.layer.layout.visibility === 'visible'
    return (
      <div className="legend-graphic-container">
        <div className="column-main" style={{filter: !isVisible ? "blur(1px)" : "initial"}}>
          {graphic({props: this.props, width, height})}
        </div>
        <div className="column-side legend-controls-container">
          <div className="legend-controls-container-inner">
            <Button onClick={this.removeLayer.bind(this)}>
              <SVG src={CLOSE_ICON} style={{fill: 'currentColor'}}/>
            </Button>
          </div>
          <div className="legend-controls-container-inner">
            <Button onClick={this.toggleVisibility.bind(this)}>
              <SVG src={isVisible ? EYE_OPEN_ICON : EYE_CLOSE_ICON} style={{fill: 'currentColor'}}/>
            </Button>
          </div>
        </div>
      </div>
    )
   }
}
export default Legend
