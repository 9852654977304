import { scaleLinear } from 'd3-scale'

export const truthyOrZero = (prop) => !!(prop || (prop === 0))

export const truthyOrDefault = (prop, defaultValue) => truthyOrZero(prop) ? prop : defaultValue

export const caseHandler = (prop, allowToFail) => {
  if (Array.isArray(prop) && ((prop[0] === 'case') || (prop[0] === 'match'))) {
    if (!allowToFail) {
      // Return one prop from the case statement; ignore the condition
      // This affects feature-state expressions as well as typical conditional rendering
      return prop[prop.length-1]
    } else {
      // Handle elsewhere
      throw new Error('Unhandled case statement in style')
    }
  }
  return prop
}

export const isInterpolatable = (prop) => !!(Array.isArray(prop) && prop[0] === 'interpolate')
export const interpolationType = (prop) => isInterpolatable(prop) ? prop[1] : null

export const interpolateHandler_getDomain = (prop) => {
  if (isInterpolatable(prop)) {
    const domainLow = prop[3]
    const domainHigh = prop[prop.length-2]
    const domainMid = (domainLow + domainHigh) / 2
    return [domainLow, domainMid, domainHigh]
  } else {
    return undefined
  }
}

export const interpolateHandler = (prop) => {
  if (isInterpolatable(prop)) {
    if (!interpolationType(prop) === 'linear') {
      // Only supports linear interpolation at present
      // Return value that is the middle of the extremes
      return (prop[4] + prop[prop.length-1]) / 2
    }
    // Return array of THREE sample values that correspond to interpolation type
    const rangeLow = prop[4]
    const rangeHigh = prop[prop.length-1]
    const dataRange = [rangeLow, rangeHigh]
    const domain = interpolateHandler_getDomain(prop)
    const samples = domain.map(Math.floor)
    const scale = scaleLinear().domain([domain[0], domain[domain.length-1]]).range(dataRange)
    return samples.map(scale)
  } else {
    return prop
  }
}

export const propParser = (prop, defaultValue, allowToFail=false) => {
  // allowToFail - passed to caseHandler
  return interpolateHandler(caseHandler(
    truthyOrDefault(prop, defaultValue), allowToFail
  ))
  // if (isInterpolatable(prop) && interpolationType !== 'linear') {
  //    return defaultValue
  // }
  // return
}
